import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme, getBodyStyle } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'default' }, 'regular')};
  position: relative;
`;

export const ContentWrapper = styled.div<{ theme: Theme }>`
  text-align: center;
  position: relative;
  z-index: 2;

  .feature-image-title {
    color: ${getColor('textWhite')};
  }
`;

export const Title = styled(Heading)`
  border-bottom: 2px solid ${getColor('brandSecondary')};
    display: inline-block;
    padding-top: 96px;
    margin-bottom: 24px;

    @media (${breakpoint.mdMin}) {
      margin-bottom: 48px;
      padding-top: 180px;
    }
`;

export const Content = styled.div<{ theme: Theme }>`
  padding: 16px;
  background-color: ${getColor('surfaceWhite')};
  box-shadow: 4px 4px 20px 0px ${getColor('neutralLightGray3')}40;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: left;
  position: relative;
  z-index: 3;

  p:not( :first-child ) {
      margin-top: 24px;
    }

  @media (${breakpoint.mdMin}) {
    padding: 32px 40px;
    gap: 24px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (${breakpoint.mdMin}) {
    &.row {
      flex-direction: row;
    }
  }

  .methodology {
    text-transform: uppercase;
  }
`;

export const HeroImage = styled.div<{ theme: Theme }>`
  position: absolute;
  z-index: 1;
  background-color: ${getColor('shadeBrandPrimary50')};
  top: 0;
  left: 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 75%;
  overflow: clip;

  @media (${breakpoint.mdMin}) {
    min-height: 320px;
  } 

  .hero-image {
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    mix-blend-mode: multiply;
  }
`;
